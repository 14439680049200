import React from "react"
import { Link } from "gatsby"

const HeaderTabs = ({ tabs }) => {
  return (
    <nav
      className="tabs is-boxed"
      role="navigation"
      aria-label="navigation"
    >
      <div className="container">
        <ul>
          {tabs.map(tab =>(
            <li key={tab.key}>
              <Link activeClassName="is-active" to={tab.path}>
                {tab.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default HeaderTabs