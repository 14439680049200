import React from "react"

import ExternalLink from "./externalLink"

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="columns">
        <div className="column">
          <h5 className="title is-5">Contact Us</h5>
          {/* <h6 className="subtitle is-6">Dr. Lee T. Murray</h6> */}
          <dl>
            <dt>Email:</dt>
            <dd>
              <a href="mailto:lee.murray@rochester.edu">lee.murray@rochester.edu</a>
            </dd>
            <dt>Office:</dt>
            <dd>
              <a href="http://maps.apple.com/?daddr=43+07+29.9+N,+77+37+51.7+W">Hutchison Hall 479</a>
            </dd>
            <dt>Lab:</dt>
            <dd>Hutchison Hall 130B</dd>
            <dt>Mail:</dt>
            <dd>RC Box 270221, Rochester, NY 14627-0221</dd>
          </dl>
        </div>
        <div className="column">
          <h5 className="title is-5">Related Links</h5>
          <ul className="is-unstyled">
            <li><ExternalLink path="http://ees.rochester.edu" title="Department of Earth &amp; Environmental Sciences">Department of Earth &amp; Environmental Sciences</ExternalLink></li>
            <li><ExternalLink path="http://pas.rochester.edu" title="Department of Physics &amp; Astronomy">Department of Physics &amp; Astronomy</ExternalLink></li>
            <li><ExternalLink path="https://www.rochester.edu/data-science/" title="Goergen Institute for Data Science">Goergen Institute for Data Science</ExternalLink></li>
            <li><ExternalLink path="https://circ.rochester.edu" title="Center for Integrated Research Computing">Center for Integrated Research Computing</ExternalLink></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer