import React from "react"
import PropTypes from "prop-types"
import { Parallax } from "react-parallax"

import Navbar from "./navbar"
import Tabs from "./tabs"

const HeroHeader = ({siteTitle, navClass}) => (
  <div className="hero-header">
    <Navbar navClass={navClass} siteTitle={siteTitle} />
  </div>
)

const HeroBody = ({ preTitle, title, subtitle}) => (
  <div className="hero-body">
    <div className="container has-text-centered">
      {preTitle && (
        <h3 className="subtitle">{preTitle}</h3>
      )}
      <h1 className="title">
        {title}
      </h1>
      {subtitle && (
        <h3 className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
      )}
    </div>
  </div>
)

const ParallaxHeader = ({parallax, children}) => {
  const { background, image, strength } = parallax
  return (
    <Parallax
      strength={strength}
      bgImage={image}
      bgImageStyle={{margin:`0 auto`}}
    >
      {background}
      {children}
    </Parallax>
  )
}

const HeaderBody = ({ navClass, preTitle, siteTitle, title, subtitle, heroClass, tabs }) => {
  return (
    <section className={`hero ${heroClass} is-dark`}>
      <HeroHeader navClass={navClass} siteTitle={siteTitle} />
      <HeroBody preTitle={preTitle} title={title} subtitle={subtitle} />
      {tabs && (
        <div className="hero-footer">
          <Tabs tabs={tabs} />
        </div>
      )}
    </section>
  )
}

const Header = class extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      percentage: 1,
      offset: 0
    }

    this.parent = props.parent;
  }


  componentDidMount() {
    const { parent } = this.props;
    this.parent = parent || document;
    this.addEventListeners();
  }

  componentDidUpdate(prevProps) {
    const { parent } = this.props;

    if (prevProps.parent !== parent) {
      this.removeListeners(prevProps.parent);
      this.parent = parent;
      if (parent) {
        this.addEventListeners();
      }
    }
  }
  
  componentWillUnmount() {
    this.removeEventListeners(this.parent);
  }

  canUseDom = () => {
    return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  }

  isScrolledOut = (element, offset = 0, useDOM) => {
    if (!useDOM) {
      return false;
    }

    const elementBottom = element.getBoundingClientRect().bottom + offset;
    return elementBottom <= 0;
  }

  onScroll = () => {
    if (!this.canUseDom()) {
      return;
    }
    window.requestAnimationFrame(this.updatePosition);
  }

  updatePosition = () => {
    if (!this.node) {
      return;
    }
    const canUseDom = this.canUseDom()

    const percentage = this.getRelativePosition(this.node, canUseDom, this.parent);
    const offset = this.getRelativeOffset(this.node, canUseDom, this.parent)
    this.setState({
      percentage,
      offset
    })
  }

  addEventListeners = () => {
    if (this.canUseDom() && this.parent) {
      this.parent.addEventListener('scroll', this.onScroll, false)
    }
  }

  removeEventListeners = () => {
    if (this.canUseDom()) {
      if (this.parent) {
        this.parent.removeEventListener('scroll', this.onScroll, false)
      }
    }
  }

  getWindowHeight = (useDOM) => {
    if (!useDOM) {
      return 0;
    }
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    return w.innerHeight || e.clientHeight || g.clientHeight;
  }

  getPercentage = (startpos, endpos, currentpos) => {
    const distance = endpos - startpos;
    const displacement = this.getOffset(startpos, currentpos);
    return displacement / distance || 0;
  }

  getOffset = (startpos, currentpos) => {
    return currentpos - startpos;
  }

  getRelativeOffset = (node, useDOM) => {
    if (!useDOM) {
      return 0;
    }

    const element = node;
    const { top } = element.getBoundingClientRect();
    // const parentHeight = this.getNodeHeight(useDOM);
    // const maxHeight = height > parentHeight ? height : parentHeight;
    // const y = Math.round(top > maxHeight ? maxHeight : top);

    return -top;
  }

  getRelativePosition = (node, useDOM) => {
    if (!useDOM) {
      return 0;
    }

    const element = node;
    const { top, height } = element.getBoundingClientRect();
    const parentHeight = this.getNodeHeight(useDOM);
    const maxHeight = height > parentHeight ? height : parentHeight;
    const y = Math.round(top > maxHeight ? maxHeight : top);

    return this.getPercentage(-height, maxHeight, y, height);
  }

  getNodeHeight = (useDOM, node) => {
    if (!useDOM) {
      return 0;
    }

    if (!node) {
      return this.getWindowHeight(useDOM);
    }

    return node.clientHeight;
  }

  render() {
    const { preTitle, siteTitle, pageTitle, subtitle, headerClass, color, tabs, parallax } = this.props
    const colorClass = `${color} ${color && 'is-bold'}`

    const hasParallaxContent = parallax !== undefined
    const parallaxClass = hasParallaxContent ? "is-parallax" : ""

    let threshold = 40
    if (headerClass === 'is-small') {
      threshold = 20
    } else if (headerClass === 'is-large') {
      threshold = 80
    }

    const { offset } = this.state
    
    const navClass = offset > threshold ? "is-white" : "is-transparent"
   
    const heroClass = `${headerClass} ${colorClass} ${parallaxClass}`
    const title = pageTitle !== "" ? pageTitle : siteTitle
    
    let header = (
      <HeaderBody navClass={navClass} preTitle={preTitle} title={title} subtitle={subtitle} siteTitle={siteTitle} heroClass={heroClass} tabs={tabs} />
    )
  
    if (hasParallaxContent) {
      header = (
        <ParallaxHeader parallax={parallax}>
          <HeaderBody navClass={navClass} preTitle={preTitle} title={title} subtitle={subtitle} siteTitle={siteTitle} heroClass={heroClass} tabs={tabs} />
        </ParallaxHeader>
      )
    }
  
    return (
      <header ref={node => { this.node = node; }}>
        {header}
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string
}

Header.defaultProps = {
  subtitle: '',
  pageTitle: '',
  color: ''
}

export default Header
