import React from "react"

import PropTypes from "prop-types"
import { Link } from "gatsby"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 40) {
      this.toggleHamburger()
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState( prevState => {
      const { active } = prevState
      const newActiveState = !active
      const navBarActiveClass = newActiveState ? 'is-active' : ''
      return { active: newActiveState , navBarActiveClass }
    })
  }

  render() {
    const { siteTitle, navClass } = this.props
    const { active, navBarActiveClass } = this.state
    const navbarColor = navClass || "is-transparent"
    return (
      <nav
        className={`navbar ${navbarColor} is-fixed-top`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title={siteTitle}>
              {siteTitle}
            </Link>
            {/* Hamburger menu */}
            <button
              type="button"
              className={`navbar-burger burger ${navBarActiveClass}`}
              data-target="navMenu"
              aria-label="menu"
              aria-expanded={active}
              onClick={() => this.toggleHamburger()}
              onKeyDown={(e) => this.handleKeyPress(e)}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          </div>
          <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" activeClassName="is-active" to="/">
                <span>Home</span>
              </Link>
              <Link className="navbar-item" activeClassName="is-active" to="/people/">
                <span>People</span>
              </Link>
              <Link className="navbar-item" activeClassName="is-active" to="/jobs/" partiallyActive>
                <span>Openings</span>
              </Link>
              <Link className="navbar-item" activeClassName="is-active" to="/research/" partiallyActive>
                <span>Research</span>
              </Link>
              <Link className="navbar-item" activeClassName="is-active" to="/publications/" partiallyActive>
                <span>Publications</span>
              </Link>
              <Link className="navbar-item" activeClassName="is-active" to="/education/">
                <span>Education</span>
              </Link>
              <Link className="navbar-item" activeClassName="is-active" to="/giss-gc/">
                <span>GISS-GC</span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}


Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
