/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import { useStaticQuery, graphql } from "gatsby"

import config from "../../content/data/SiteConfig";

function SEO({ description, lang, meta, title, image, altTitle, pagePath, pageSEO }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const { googleSiteVerification } = config
  let pageTitle = config.site.title
  let shareTitle = pageTitle
  if (altTitle) {
    pageTitle = altTitle
    shareTitle = `${altTitle} | ${config.site.titleShort}`
  } else if (title && title !== "Home") {
    pageTitle = title
    shareTitle = `${title} | ${config.site.titleShort}`
  }

  const { siteUrl } = site.siteMetadata

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: siteUrl,
      name: pageTitle,
      alternateName: config.site.titleAlt ? config.site.titleAlt : ""
    }
  ]
  if (pageSEO) {
    const { pageSchema } = pageSEO
    pageSchema.forEach((schema) => {
      schemaOrgJSONLD.push(schema)
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={`%s | ${config.site.titleShort}`}
    >

      {/* General tags */}
      <meta name="description" content={metaDescription} />
      <meta name="image" content={image} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={pagePath || siteUrl} />
      {pageSEO ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />}
      <meta property="og:title" content={shareTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={config.userTwitter ? config.userTwitter : ""}
      />
      <meta name="twitter:title" content={shareTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />

      <meta name="google-site-verification" content={googleSiteVerification} />

      {meta}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
