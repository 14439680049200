/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'


import Header from "./header"
import Footer from "./footer"

library.add(fab, faFilePdf, faFilter, faTimes)

const Layout = ({ headerProps, children }) => {
  const headerClass = headerProps.isFullHeight ? "is-large" : headerProps.headerSize || "is-medium"
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          titleShort
        }
      }
    }
  `)

  return (
    <div className="siteWrapper">
      <Header 
        preTitle={headerProps.preTitle}
        pageTitle={headerProps.title}
        subtitle={headerProps.subtitle}
        headerClass={headerClass}
        parallax={headerProps.parallax}
        tabs={headerProps.tabs}
        color="is-primary"
        siteTitle={data.site.siteMetadata.titleShort} 
      />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
