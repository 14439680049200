const siteTitle = "Atmospheric Chemistry and Climate Group at the University of Rochester"

const config = {
  site: {
    description: "Research group studying chemistry-climate interactions at the University of Rochester", // Website description used for RSS feeds/meta description tag.
    prefix: "", // "/ees/atmos", // Prefixes all links.
    title: `Lee T. Murray | ${siteTitle}`, // Site title.
    titleShort: "UR Atmos", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    titleAlt: siteTitle, // Alternative site title for SEO.
    titleTemplate: `%s | ${siteTitle}`,
    url: "http://atmos.earth.rochester.edu", // Domain of your website without pathPrefix.
  },
  googleAnalyticsID: "UA-53428190-2", // GA tracking ID.
  googleSiteVerification: "9RWPbdAAz42zIN6UZilRq-wwsQrbyTngYT62sbz3GcM",  // Google Search Webmaster tools
  userName: "Lee Thomas Murray", // Username to display in the author segment.
  userEmail: "lee.murray@rochester.edu", // Email used for RSS feed's author segment
  userTwitter: "@leetmurray", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Rochester, New York", // User location to display in the author segment.
  dateFromFormat: "YYYY-MM-DDTHH:mm:ssZ", // Date format used in the publications.
  dateFormat: "DD/MM/YYYY", // Date format for display.
}

module.exports = config;
